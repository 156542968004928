var __requests = [];

export class LazyLoadClient {
   constructor(id, api) {
       this.id = id;
       this.api = api;
       this.data = null;
       this.loaded = false;
       this.loading = false;
   }

   get() {
       if (
           __requests[this.id] &&
           __requests[this.id] != this
       ) {
           return __requests[this.id].get();
       }
       if (!this.loaded && !this.loading) {
           this.loading = true;
           __requests[this.id] = this;
           this.api.getCliente(this.id).then(res => {
               this.data = res.customer;
               this.loaded = true;
               this.loading = false;
           });
           return null;
       }
       return this.data;
   }

   async getNow() {
      if (
          __requests[this.id] &&
          __requests[this.id] != this
      ) {
          return __requests[this.id].getNow();
      }
      if (!this.loaded && !this.loading) {
         this.loading = true;
         __requests[this.id] = this;
         let res = await this.api.getCliente(this.id);
         this.data = res.customer;
         this.loaded = true;
         this.loading = false;
      }
      return this.data;
  }
}


