<template>
    <span v-if="!editing && preview" v-html="renderedValue" @click="edit" class="renderArea"/>
    <input v-else type="text" v-model="model" ref="input" @blur="stopEdit"/>
</template>

<script>
import MarkDownIt from "markdown-it";

export default {
    props: {
        value: {
            required: true,
        },
        preview: {
            type: Boolean,
            default: true,
        }
    },

    data() {
        return {
            editing: false,
            markdown: new MarkDownIt(),
        };
    },


    computed: {
        renderedValue() {
	    if (!this.model) return "";
            return this.markdown.renderInline(this.model);
        },

        model: {
            get() {
                return this.value;
            },

            set(newValue) {
                this.$emit("input", newValue);
            }
        }
    },

    watch: {
        renderedValue: {
            immediate: true,
            handler(newValue) {
                if (!newValue)
                    this.editing = true;
            }
        }
    },

    methods: {
        edit() {
            this.editing = true;
            this.$nextTick(()=>{
                this.$refs.input.focus();
            })
        },

        stopEdit(event) {
            if (this.renderedValue)
                this.editing = false;
            this.$emit("blur", event);
        }
    }
};
</script>

<style lang="scss" scoped>
.renderArea {
    display: inline-block;
}
</style>
