import RestAPI from "./RestAPI";

export class OfficeGestAPI {
   constructor(officeGestURL, username, token) {
      this.rest = new RestAPI(officeGestURL+"/api/", username, token);
   }

   async getFaturaFornecedor(idFatura) {
      return this.rest.get('purchases/documents/FaF/'+idFatura)
   }

   async getFatura(idFatura) {
      return this.rest.get('sales/documents/FT/'+idFatura)
   }

   async getFaturasEmitidas(desde) {
      let result = await this.rest.get('sales/documents/FT/?filter[start_date]='+desde);
      console.log("GetFaturasEmitidas");
      console.log(result);
      return result;
   }

   async getOrcamento(idOrcamento) {
      return this.rest.get('sales/documents/OR/'+idOrcamento)
   }

   async getOrcamentos() {
      return this.rest.get('sales/documents/OR/?order=date&sorder=DESC');
   }

   async getArtigo(idArtigo) {
      return this.rest.get('stocks/articles/'+btoa(idArtigo));
   }

   async getCliente(idCliente) {
      return this.rest.get('entities/customers/'+idCliente);
   }
}