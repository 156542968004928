<template>
    <div :class="{lockedBar: bloqueado}">
        <div class="topo" :class="{lockedBar: bloqueado}">
            <div>
                <span>Documento</span> {{documento.numero}}
                <span v-if="bloqueado" class="locked">(Bloqueado)</span>

                <button @click="bloquear" class="botaoComando" style="margin-left: 4px;">{{bloqueado? "Desbloquear":"Bloquear"}}</button>
            </div>
            <div class="msgErro">{{mensagemErro}}</div>
            <div class="d-flex" style="gap: 4px;">
                <button v-if="documento.id" class="botaoComando"  @click="duplicar">Duplicar Orçamento</button>
                <button v-if="!bloqueado" class="botaoComando" @click="guardarOrcamento">{{documento.id? "Atualizar Orçamento": "Guardar Orcamento"}}</button>
                <button @click="preVisualizar" class="botaoComando" >Pré-Visualizar PDF</button>
            </div>
        </div>
        <div class="container py-4"  >
            <div class="areaCabecalho">
                <div class="titulo">{{documento.id?"Editar Orçamento":"Novo Orçamento"}}</div>
                <div class="grelhaCampos">
                    <span>Documento</span> <input type="text" v-model="documento.numero" :readonly="bloqueado"/>
                    <span>Data</span> <input type="date" v-model="documento.data_criacao" :readonly="bloqueado"/>
                    <span>Validade</span> <input type="date" v-model="documento.validade" :readonly="bloqueado"/>
                    <span>Cliente</span> <input type="text" v-model="documento.nome_cliente" :readonly="bloqueado"/>
                    <span>Morada</span> <input type="text" v-model="documento.morada_cliente" :readonly="bloqueado"/>
                    <span>Morada 2</span> <input type="text" v-model="documento.morada2_cliente" :readonly="bloqueado"/>
                    <span>Código Postal</span> <input type="text" v-model="documento.codigo_postal_cliente" :readonly="bloqueado"/>
                    <span>NIF</span> <input type="text" v-model="documento.nif_cliente" :readonly="bloqueado"/>
                    <span>Telefone</span> <input type="text" v-model="documento.telefone_cliente" :readonly="bloqueado"/>
                    <span>E-Mail</span> <input type="text" v-model="documento.email_cliente" :readonly="bloqueado"/>
                    <span>Nome de Contacto</span> <input type="text" v-model="documento.nome_contacto" :readonly="bloqueado"/>
                    <span>Notas</span> <textarea v-model="documento.notas" :readonly="bloqueado"/>
                </div>
            </div>

            <div v-for="(area, index) in documento.areas" :key="'area_' + index">
                <div class="zonaDinamica">

                    <div class="areaDados">
                        <div class="tituloArea">
                            <MarkDownInput v-model="area.nome" class="inputArea" placeholder="Área" :preview="false"  :readonly="bloqueado"/>
                            <label style="margin:0; padding: 0px 0px 0px 5px;"><input type="checkbox" value="isQuebrarPagina(area)" @change="area.quebrar_pagina = !isQuebrarPagina(area)" :disabled="bloqueado"  /> Nova página</label>
                        </div>
                        <div>
                            <MarkDownTextArea class="inputAnotacoes"
                                placeholder="Anotações"
                                :preview="false"
                                :rows="2"
                                v-model="area.anotacoes"  :readonly="bloqueado" />
                        </div>
                        <div v-for="(artigo, index) in area.artigos" :key="'artigo_'+index">
                            <div class="zonaDinamica">

                                <div  class="artigo">
                                    <div class="grelhaArtigo">
                                        <div class="espacoBranco"></div>
                                        <div>
                                            <MarkDownInput
                                                class="inputArtigo"
                                                placeholder="Artigo"
                                                :preview="false"
                                                v-model="artigo.descricao"
                                                :readonly="bloqueado"
                                            />
                                        </div>

                                            <button v-if="!bloqueado" class="dynamicButton" @click="procurarArtigo(artigo.descricao, artigo)"><font-awesome-icon icon="search" /></button>
                                    </div>
                                    <div v-if="artigoComResultados===artigo" class="areaResultadosPesquisa">
                                            <div style="display: flex; flex-direction:row;justify-content:space-between;">
                                                <div>Resultados da pesquisa:</div>
                                                <button class="dynamicButton" @click="limparPesquisa">X</button>
                                            </div>
                                        <div class="resultados">
                                            <div v-for="result in resultadosPesquisaArtigos" :key="result.id" class="resultadoPesquisa">
                                                <div>
                                                   <div>Orçamento: <a :href="'/orcamentos/editar/'+result.documento.id" target="_blank">{{result.documento.numero}} - {{result.documento.nome_cliente}}</a></div>
                                                    <div>Área: <span class="bold">{{result.area.nome}}</span></div>
                                                    <div>
                                                         <span>Titulo:</span>
                                                        <span class="bold">{{result.descricao}}</span>

                                                        <span>Valor:</span>
                                                        <span class="bold">{{result.valor_sem_iva}}</span>

                                                        <span>Horas:</span>
                                                        <span class="bold">{{result.horas_orcamentadas}}</span>

                                                        <span>Aquisições:</span>
                                                        <span class="bold">{{result.valor_aquisicoes}}</span>
                                                    </div>

                                                    <div class="descricao">{{result.notas}}</div>

                                                </div>
                                                <div style="display: flex; flex-direction:column; align-items:center;justify-content:center;gap:0.25rem;">
                                                    <button class="botaoComando" @click="usarArtigo(result)">Usar este artigo</button>
                                                    <!--<button class="botaoComando" @click="copiarDescricao(result)">Copiar descrição</button>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="areaArtigo">
                                        <MarkDownTextArea class="inputNotas" :rows="20" :preview="false" placeholder="Notas" v-model="artigo.notas"  :readonly="bloqueado"/>
                                        <div class="areaPrecoArtigo">
                                            <div class="preco">
                                                <span>€</span>
                                                <input type="number" class="inputValor" v-model="artigo.valor_sem_iva"  :readonly="bloqueado"/>
                                            </div>
                                            <div class="notasPreco">
                                                <!--<select class="inputNotasValor" v-model="artigo.notas_custo" :readonly="bloqueado">
                                                    <option value="[Unitário]">[Unitário]</option>
                                                    <option value="[Avença Mensal]">[Avença Mensal]</option>
                                                    <option value="[Avença Anual]">[Avença Anual]</option>
                                                    <option :value="null">Outro</option>
                                                </select> -->
                                                <MarkDownInput class="inputNotasValor" :preview="false" v-model="artigo.notas_custo"  :readonly="bloqueado" list="notasPrecos" @blur="regenerarListaNotasPrecos"/>
                                            </div>
                                            <div class="especificacoesCustos">
                                                <div class="horas">
                                                    <div>Horas Orçamentadas</div>
                                                    <div><input type="number" class="inputValor" v-model.number="artigo.horas_orcamentadas"  :readonly="bloqueado"/></div>
                                                    <div class="horas_calculadas" v-if="artigo.horas_orcamentadas != null && artigo.horas_orcamentadas != ''">
                                                        Orçamentado a <b>{{((artigo.valor_sem_iva-artigo.valor_aquisicoes)/artigo.horas_orcamentadas).toFixed(2)}}€/h</b>
                                                    </div>
                                                    <div class="horas_calculadas" v-if="artigo.horas_orcamentadas==null || artigo.horas_orcamentadas=='' || Math.abs((artigo.valor_sem_iva-artigo.valor_aquisicoes)/artigo.horas_orcamentadas - 30)>0.01">
                                                        Seriam <b>{{((artigo.valor_sem_iva-artigo.valor_aquisicoes)/30).toFixed(2)}}h</b> a 30€/hora
                                                    </div>

                                                </div>
                                                <div class="horas">
                                                    <div style="padding-top:16px">
                                                        Aquisições Orçamentadas
                                                    </div>
                                                    <div class="preco">
                                                        <span>€</span>
                                                        <input type="number" class="inputValor" v-model="artigo.valor_aquisicoes"  :readonly="bloqueado"/>
                                                    </div>
                                                </div>
                                                <div class="horas">
                                                    <div style="padding-top:16px">
                                                        Quadro de Resumo
                                                    </div>
                                                    <div class="preco">
                                                        <input type="text" class="inputQuadro" v-model="artigo.grupo_resumo" list="quadrosResumo" @blur="regenerarListaQuadrosResumo">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!bloqueado" class="operacoesDinamicas">
                                    <button class="dynamicButton" v-if="index>0" @click="moveArticleUp(area, index)"><font-awesome-icon icon="arrow-up" /></button>
                                    <button class="dynamicButton danger" v-if="area.artigos.length>1" @click="removeArticle(area, index)"><font-awesome-icon icon="times" /></button>
                                    <button class="dynamicButton" v-if="index < area.artigos.length-1" @click="moveArticleDown(area, index)"><font-awesome-icon icon="arrow-down" /></button>
                                </div>
                            </div>
                        </div>
                        <div v-if="!bloqueado" class="areaBotao">
                            <button class="botaoPequeno" @click="adicionarArtigo(area)">Adicionar Artigo</button>
                        </div>
                    </div>
                    <div v-if="!bloqueado" class="operacoesDinamicas">

                        <button class="dynamicButton" v-if="index>0" @click="moveAreaUp(index)"><font-awesome-icon icon="arrow-up" /></button>
                        <button class="dynamicButton danger" v-if="documento.areas.length>1" @click="removeArea(index)"><font-awesome-icon icon="times" /></button>
                        <button class="dynamicButton" v-if="index < documento.areas.length-1" @click="moveAreaDown(index)"><font-awesome-icon icon="arrow-down" /></button>
                    </div>
                </div>
            </div>
            <div v-if="!bloqueado" class="areaBotao">
                <button class="botaoGrande"@click="adicionarArea()">Adicionar Área</button>
            </div>
            <div class="areaTotal zona">
               <div> Horas Orçamentadas: <b>{{ this.horasTotais }}h</b></div>
               <div> Valor Total de Aquisições: <b>€{{ this.aquisicoesTotais }}</b></div>
               <div> Valor Total Orçamentado: <b>€{{ this.valorTotal }}</b></div>
            </div>
            <div class="grelhaObservacoes">
               <div class="zona">
                  <div class="identificadorZona">OBSERVAÇÕES</div>
                  <textarea class="inputObservacoes" rows="4" v-model="documento.observacoes" />
               </div>
               <div class="zona">
                  <div class="identificadorZona">CONDIÇÕES DE PAGAMENTO</div>
                  <textarea class="inputObservacoes" rows="4" v-model="documento.condicoes_pagamento" />
               </div>
            </div>

            <form
                class="hidden"
                method="POST"
                target="_blank"
                ref="formulario"
                action="/previewPDF"
            >
                <input type="hidden" name="dados" v-model="processedData" />
            </form>
            <div>
                <div v-for="grupo in obterGrupos()" :key="grupo.grupo">

                    <table class="tabelaResumo">
                        <thead>
                            <tr class="grupo">
                                <th colspan="3">{{grupo.grupo}}</th>
                            </tr>
                            <tr class="cabecalho">
                                <th>Área</th>
                                <th>Artigo</th>
                                <th class="valor">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="area in grupo.areas">
                                <template v-for="(artigo, index) in area.artigos">
                                    <tr>
                                        <td v-if="index==0" :rowspan="area.artigos.length">{{area.area}}</td>
                                        <td>{{artigo.descricao}}</td>
                                        <td class="valor">{{Number(artigo.valor_sem_iva).toFixed(2)}} €</td>
                                    </tr>
                                </template>
                                <!-- <tr>
                                    <td colspan="2" class="valor total">Sub-total:</td>
                                    <td class="valor total">{{area.valorTotal}}€</td>
                                </tr> -->
                            </template>
                            <tr>
                                <td colspan="2" class="valor total">Total: </td>
                                <td class="valor total">{{Number(grupo.valorTotal).toFixed(2) }} €</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <datalist id="quadrosResumo">
            <option v-for="grupo in gruposResumo" :value="grupo">{{grupo}}</option>
        </datalist>
        <datalist id="notasPrecos">
            <option v-for="nota in notasPrecos" :value="nota">{{nota}}</option>
        </datalist>
    </div>
</template>

<script>

import dayjs from "dayjs";
import MarkDownInput from "./MarkdownInput.vue";
import MarkDownTextArea from "./MarkdownTextArea.vue";

export default {
    components: {
        MarkDownInput,
        MarkDownTextArea
    },

    props: {
        id: Number,
        copy: Boolean
    },

    data() {
        return {
            mensagemErro: "",
            timeout: null,
            pesquisaArtigos: "",
            resultadosPesquisaArtigos: [],
            artigoComResultados: null,
            gruposResumo: ['Valores Fixos', 'Valores Mensais', 'Valores Anuais'],
            notasPrecos: ['[Unitário]', '[Avença Mensal]', '[Avença Anual]'],
            debouncedSearch: _.debounce(()=>this.pesquisarArtigos(this.pesquisaArtigos), 500),
            documento: {
                id: null,
                data_criacao: dayjs().format("YYYY-MM-DD"),
                nome_cliente: "",
                morada_cliente: "",
                morada2_cliente: "",
                codigo_postal_cliente: "",
                nif_cliente: "",
                telefone_cliente: "",
                email_cliente: "",
                nome_contacto: "",
                notas: "",
                numero: "",
                validade: dayjs().add(30, "day").format("YYYY-MM-DD"),
                observacoes: "Todo o conteúdo será facultado pelo cliente.\nEste documento não serve de fatura.\nValores apresentados sujeitos à taxa do IVA em vigor.",
                condicoes_pagamento: "30% do valor no ato da adjudicação\n40% do valor na aprovação dos trabalhos desenvolvidos\n30% do valor na entrega do projeto",
                bloqueado: false,

                areas: [
                    {
                        nome: "",
                        anotacoes: "",
                        quebrar_pagina: false,

                        artigos: [
                            {
                                descricao: "",
                                valor_sem_iva: 0,
                                notas: "",
                                notas_custo: null,
                                horas_orcamentadas: null,
                                valor_aquisicoes: 0,
                                grupo_resumo: "Valores Fixos",
                            }
                        ]
                    }
                ],
            },
        };
    },

    async mounted() {
        if (this.id>0) {
            await this.getDocument(this.id);
            if (this.copy) {
                this.documento.id = null;
                this.documento.numero = "";
                this.documento.data_criacao = dayjs().format("YYYY-MM-DD");
                this.documento.validade = dayjs().add(1, "month").format("YYYY-MM-DD");
                this.documento.bloqueado = false;
                this.getNextNumber();
            }
        } else {
            this.getNextNumber();
        }
    },

    computed: {
        processedData() {
            let result = {
                document: {
                    date: dayjs(this.documento.data_criacao).format("YYYY-MM-DD") +" 00:00",
                    datedue: dayjs(this.documento.validade).format("YYYY-MM-DD") +" 00:00",
                    nomefact: this.documento.nome_cliente,
                    documentnumber: this.documento.numero,
                    moradafact: this.documento.morada_cliente,
                    morada2fact: this.documento.morada2_cliente,
                    codpostalent: this.documento.codigo_postal_cliente,
                    observacoes: this.documento.observacoes,
                    condicoes_pagamento: this.documento.condicoes_pagamento,
                    nif: this.documento.nif_cliente,
                    nome_contacto: this.documento.nome_contacto,
                    notas: this.documento.notas,
                },
                areas: [],
                quadrosResumo: [],
            };

            for (let area of this.documento.areas) {
                let elem = {
                    nome: area.nome,
                    anotacoes: area.anotacoes,
                    quebrarPagina: area.quebrar_pagina,
                    artigos: []
                };
                for (let artigo of area.artigos) {
                    elem.artigos.push({
                        linha: {
                            description: artigo.descricao,
                            totalwithoutvat: artigo.valor_sem_iva,
                        },
                        notas: artigo.notas,
                        notasCusto: artigo.notas_custo
                    });
                }
                result.areas.push(elem);
            }
            result.quadrosResumo = this.obterGrupos();

            return JSON.stringify(result, null, 2);
        },

        bloqueado: {
            get() {
                return this.documento.bloqueado==1 || this.documento.bloqueado==true;
            },

            set(valor) {
                this.$set(this.documento, "bloqueado", valor);
            }
        },

        valorTotal() {
           let total = 0;
           for (let area of this.documento.areas) {
              for (let artigo of area.artigos)
               total += Number(artigo.valor_sem_iva);
           }
           return total.toFixed(2);
        },

        horasTotais() {
           let total = 0;
           for (let area of this.documento.areas) {
              for (let artigo of area.artigos)
               total += Number(artigo.horas_orcamentadas);
           }
           return total.toFixed(2);
        },

         aquisicoesTotais() {
           let total = 0;
           for (let area of this.documento.areas) {
              for (let artigo of area.artigos)
               total += Number(artigo.valor_aquisicoes);
           }
           return total.toFixed(2);
        },
    },

    watch: {
        pesquisaArtigos() {
            this.debouncedSearch();
        }
    },

    methods: {
        async pesquisarArtigos() {
            if (this.pesquisaArtigos.trim()==="") {
                this.resultadosPesquisaArtigos = [];
                return;
            }
            let texto = this.pesquisaArtigos.toLowerCase();
            const acentos = "àèìòùáéíóúãõç";
            const semAcentos = "aeiouaeiouaoc";
            for (let i=0; i<acentos.length; i++) {
                let acento = acentos.charAt(i);
                let sem = semAcentos.charAt(i);
                texto = texto.replaceAll(acento, sem);
            }
            console.log("Pesquisar por " + texto);
            let result = await axios.get("/api/orcamentos/artigos/pesquisa", {params: {text: texto}});
            this.resultadosPesquisaArtigos = result.data;

            console.log(result.data);
        },

        async procurarArtigo(texto, artigo) {
            this.resultadosPesquisaArtigos = [];
            this.artigoComResultados = artigo;
            if (texto.trim()==="") {
                this.resultadosPesquisaArtigos = [];
                return;
            }
            texto = texto.toLowerCase();
            const acentos = "àèìòùáéíóúãõç";
            const semAcentos = "aeiouaeiouaoc";
            for (let i=0; i<acentos.length; i++) {
                let acento = acentos.charAt(i);
                let sem = semAcentos.charAt(i);
                texto = texto.replaceAll(acento, sem);
            }

            console.log("Pesquisar por " + texto);
            let result = await axios.get("/api/orcamentos/artigos/pesquisa", {params: {text: texto}});
            result.data.sort((a, b) => {
                return -a.documento.data_criacao.localeCompare(b.documento.data_criacao);
            });
            this.resultadosPesquisaArtigos = result.data;

            console.log(result.data);
        },

        usarArtigo(artigo) {
            if (!this.artigoComResultados)
                return;
            if (this.artigoComResultados.notas.trim() !== ""){
               if (!confirm("Vai substituir a informação já existente no artigo.\nDeseja continuar?"))
                  return;
            }
            this.artigoComResultados.descricao = artigo.descricao;
            this.artigoComResultados.notas = artigo.notas;
            this.artigoComResultados.valor_sem_iva = artigo.valor_sem_iva;
            this.artigoComResultados.notas_custo = artigo.notas_custo;
            this.artigoComResultados.horas_orcamentadas = artigo.horas_orcamentadas;
            this.artigoComResultados.valor_aquisicoes = artigo.valor_aquisicoes;
            this.limparPesquisa();
        },

        limparPesquisa() {
            this.resultadosPesquisaArtigos = [];
            this.artigoComResultados = null;
        },

        async getDocument(id) {
            let result = await axios.get("/api/orcamentos/documentos/"+id);
            this.documento = result.data;
        },

        adicionarArtigo(area) {
            area.artigos.push({
                                descricao: "",
                                valor_sem_iva: 0,
                                notas: "",
                                notas_custo: null,
                                horas_orcamentadas: null,
                                valor_aquisicoes: 0,
                                grupo_resumo: "Valores Fixos",
                            });
        },

        adicionarArea() {
            this.documento.areas.push({
                        nome: "",
                        anotacoes: "",
                        quebrar_pagina: false,

                        artigos: [
                            {
                                descricao: "",
                                valor_sem_iva: 0,
                                notas: "",
                                notas_custo: null,
                                horas_orcamentadas: null,
                                valor_aquisicoes: 0,
                                grupo_resumo: "Valores Fixos",
                            }
                        ]
                    });
        },

        moveAreaUp(index) {
            let area = this.documento.areas[index];
            this.documento.areas.splice(index, 1);
            this.documento.areas.splice(index-1, 0, area);
        },

        moveAreaDown(index) {
            let area = this.documento.areas[index];
            this.documento.areas.splice(index, 1);
            this.documento.areas.splice(index+1, 0, area);
        },

        removeArea(index) {
            if (confirm("Tem a certeza que deseja remover a área \"" + this.documento.areas[index].nome+"\"?"))
                this.documento.areas.splice(index, 1);
        },

        moveArticleUp(area, index) {
            let artigo = area.artigos[index];
            area.artigos.splice(index, 1);
            area.artigos.splice(index-1, 0, artigo);
        },

        moveArticleDown(area, index) {
            let artigo = area.artigos[index];
            area.artigos.splice(index, 1);
            area.artigos.splice(index+1, 0, artigo);
        },

        removeArticle(area, index) {
            if (confirm("Tem a certeza que deseja remover o artigo \"" + area.artigos[index].descricao+"\"?"))
                area.artigos.splice(index, 1);
        },

        preVisualizar() {
            console.log(this.processedData);
            setTimeout(() => {
                this.$refs.formulario.submit();
            }, 250);
        },

        async guardarOrcamento() {
            try {
                let result = await axios.post("/api/orcamentos/documentos", {documento: this.documento}, { headers: {
                    'Authorization': 'Bearer ' + document.getElementsByName('cross-token')[0].getAttribute('content'),
                }});
                if (_.isNumber(result.data)) {
                    this.documento.id = result.data;
                } else {
                    this.showMessage("Erro ao guardar orçamento!", result.data);
                }
            } catch (error) {
                this.showMessage("Erro ao guardar orçamento!", error);
            }
        },

        showMessage(message, error) {
            if (_.isString(error)) {
                this.mensagemErro = message + "\n" + error.split("\n")[1];
            } else {
                this.mensagemErro = message + "\n" + error.message +"\n" + error.response.data.message + "\n" + error.response.data.exception;
            }
            if (this.timeout)
                clearTimeout(this.timeout);
            this.timeout = setTimeout(()=>{this.timeout=null; this.mensagemErro="";}, 10000);
        },

        async getNextNumber() {
            try {
                let result = await axios.get("/api/orcamentos/documentos/proximoNumero");
                this.documento.numero = result.data;
            } catch (error) {
                this.showMessage("Erro ao obter numeração do documento", error);
            }
        },

        isQuebrarPagina(area) {
            let valor = area.quebrar_pagina;
            if (!Number.isNaN(valor))
                return !!Number(valor);
            return !!valor;
        },

        async bloquear() {
            this.bloqueado = !this.bloqueado;
            await this.guardarOrcamento();
        },

        async duplicar() {
            if (!this.bloqueado) {
                await this.guardarOrcamento();
            }
            window.open('/orcamentos/copiar/' + this.documento.id, "_blank").focus();
        },

        regenerarListaQuadrosResumo() {
            let result = ['Valores Fixos', 'Valores Mensais', 'Valores Anuais'];

            for (let area of this.documento.areas) {
                for (let artigo of area.artigos) {
                    let quadro = (artigo.grupo_resumo ?? "").trim();
                    if (quadro !== "" && !result.includes(quadro))
                        result.push(quadro);
                }
            }

            this.gruposResumo = result;
        },

        regenerarListaNotasPrecos() {
            let result = ['[Unitário]', '[Avença Mensal]', '[Avença Anual]'];
            for (let area of this.documento.areas) {
                for (let artigo of area.artigos) {
                    let nota = (artigo.notas_custo ?? "").trim();
                    if (nota !== "" && !result.includes(nota))
                        result.push(nota);
                }
            }

            this.notasPrecos = result;
        },

        obterGrupos() {
            let result = [];

            for (let area of this.documento.areas) {
                for (let artigo of area.artigos) {
                    let grupos = artigo.grupo_resumo?.split(";");
                    if (grupos===null||grupos===undefined) continue;
                    for (let grupo of grupos) {
                        if ((grupo ?? "").trim() === "")
                            continue;
                        let grupoExistente = result.find(x => x.grupo === grupo);
                        if (!grupoExistente) {
                            let index = result.push({grupo, areas: [], valorTotal: 0});
                            grupoExistente = result[index-1];
                        }
                        let areaExistente = grupoExistente.areas.find(x => x.area === area.nome);
                        if (!areaExistente) {
                            let index = grupoExistente.areas.push({area: area.nome, artigos: [], valorTotal: 0});
                            areaExistente = grupoExistente.areas[index-1];
                        }
                        areaExistente.artigos.push(artigo);
                        areaExistente.valorTotal += Number(artigo.valor_sem_iva);
                        grupoExistente.valorTotal += Number(artigo.valor_sem_iva);
                    }
                }
            }

            return result;
        },
    }
};
</script>

<style lang="scss" scoped>

input {
    border: 1px solid #c3c3c3;
    border-radius: 4px;

    &:focus {
        outline: none;
        border: 1px solid #2874e0;
    }
}

textarea {
    border: 1px solid #c3c3c3;
    border-radius: 4px;

    &:focus {
        outline: none;
        border: 1px solid #2874e0;
    }
}

.grelhaCampos {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 4px;
  padding: 0.5rem;
  border: 1px solid #a3a3a3;
  border-radius: 0.5rem;
  background: #f1f1f1;
}

.areaCabecalho {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0.5rem calc(0.5rem + 30px) 0.5rem 0.5rem;
}

.areaDados {
  border: 1px solid #a3a3a3;
  border-radius: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background: #f1f1f1;
  padding: 0.5rem;
}

.tituloArea {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid black;
    margin-bottom: 0.5rem;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items:center;
}

.grelhaArtigo {
  display: grid;
  grid-template-columns: 30px 1fr max-content;
  align-items:center;
}

.inputArea {
  width: 100%;
  font-size: 1.5rem;
}

.inputAnotacoes {
    width: 100%;
    font-size: 0.7rem;
}

.inputArtigo {
    background: #60a5fa;
    width: 100%;
    color: white;
    border: 1px solid #00000000;
    border-radius: 0px;
    font-size: 1.25rem;
    padding-left: 0.5rem;
}

.inputArtigo::placeholder {
    color: #cfcfcf;
}

.areaArtigo {
    display: grid;
    grid-template-columns: 8fr 2fr;
    align-items: center;
}

.areaPrecoArtigo {
    margin-left: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    border-left: 1px solid #a3a3a3;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preco {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.inputValor {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    width: 100%;
}

.inputQuadro {
    text-align: center;
    width: 100%;
}

.inputNotasValor {
    width: 100%;
    text-align: center;
    font-size: 0.7rem;
    color: #3c3c3c;
}

.titulo {
    align-self: center;
    justify-self: center;
    font-size: 3rem;
}

.areaBotao {
    text-align: center;
    padding: 0.5rem;
}

.botaoPequeno {
    padding: 0.25rem 5rem;
    font-size: 0.8rem;
    color: black;
    border: 1px solid #c3c3c3;
    background: #f1f1f1;
    opacity: 0.1;
    border-radius: 99999px;
    &:hover {
        opacity: 1;
    }
}

.botaoGrande {
  padding: 0.25rem 8rem;
    font-size: 1rem;
    color: black;
    border: 1px solid #c3c3c3;
    background: #f1f1f1;
    opacity: 0.1;
    border-radius: 99999px;
    &:hover {
        opacity: 1;
    }
}

.espacoBranco {
    background: #c3c3c3;
    width: 100%;
    height: 100%;
}

.notasPreco {
    width:100%;
}

.artigo {
    margin-bottom: 1rem;
}

.zonaDinamica {
    display: grid;
    grid-template-columns: 1fr 30px;
}

.operacoesDinamicas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dynamicButton {
    background: none;
    border: none;
    width: 30px;
    border: 1px solid #c3c3c300;

    &:hover {
        border: 1px solid #c3c3c3;
        background: #f1f1f1;
    }

    &.danger {
        color: red;
    }
}

.inputObservacoes {
    width: 100%;
}

.zona {
    border: 1px solid #a3a3a3;
  border-radius: 0.5rem;
  background: #f1f1f1;
  padding: 0.5rem;
}

.identificadorZona {
    background:#60a5fa;
    color: white;
    text-align: center;
}

.grelhaObservacoes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
}

.horas {
    //margin-top: 1rem;
    text-align: center;
}

.horas_calculadas {
    font-size: 0.7rem;
    //color: #4c4c4c;
    text-align: center;
}

.topo {
    position: sticky;
    top: 0px;
    left: 0px;
    right: 0px;
    background: #ffffff;
    padding: 4px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #0000001c;
    box-shadow: #00000014 -1px 2px 4px 0px;
}

.lockedBar {
    background: repeating-linear-gradient(45deg, #d4d4d4, #d4d4d4 10px, #d8d8d8 10px, #d8d8d8 20px);
}

.locked {
    color: red;
    font-weight: bold;
}

.botaoComando {
    background: #f0f0f0;
    color: #000000;
    border-radius: 999px;
    padding: 1px 16px;
    box-shadow: #a3a3a3 0px 1px 4px;


    &:hover {
        background: #ffffff;
        color: #393939;
    }

    &:focus {
        border-color: #60a5fa;
        outline: none;
    }

    &:active {
        box-shadow: none;
        padding: 1px 15px 1px 17px;
        box-shadow: inset #a3a3a3 0px 1px 4px;
    }
}

.especificacoesCustos {
    background: #d8d8d8;
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: 4px;
    color: #6b6b6b;
}

.areaTotal {
   display: flex;
   justify-content: space-between;
   align-items: center;
   position: sticky;
   bottom: 0px;
   margin: 0.5rem 0px;
}

.msgErro {
    color: red;
    white-space: pre;
    margin-inline: 1rem;
}

.areaResultadosPesquisa {
    margin-left: 3rem;
    margin-bottom: 0.5rem;
    background: white;
    border: 1px solid #0000001c;
    border-radius: 2px;
    padding: 0.25rem;
    box-shadow: #0000001c 2px 2px 4px;
}

.resultados {
    max-height: 20rem;
    overflow-y: auto;
}

.resultadoPesquisa {
    display: grid;
    grid-template-columns: 1fr max-content;
    font-size: 0.7rem;

    &:not(:last-child) {
        margin-bottom: 0.25rem;
        border-bottom: 1px solid #0000003c;
        padding-bottom: 0.25rem;
    }

    .descricao {
        white-space: pre;
        background: white;
    }

    .bold {
        font-weight: bold;
    }
}

.tabelaResumo {
    width: 100%;
    margin-top: 1rem;
    border-collapse: collapse;

    td, th {
        padding: 0.25rem;
        border: 1px solid #0000001c;
    }

    thead {
        .grupo {
            text-align: center;
            font-size: 1.25rem;
        }

        .cabecalho {
            background: #60a5fa;
            color:white;
        }
    }

    .valor {
        text-align: right;
    }

    .total {
        font-weight: bold;
    }
}

</style>
