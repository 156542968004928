<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">Funcionalidades disponíveis</div>

                    <div class="card-body">
                        <ul>
                            <li>
                                <b>Orçamentos</b>
                                <ul class="sublist">
                                    <li>
                                        <a href="baseDadosOrcamentos">Base de Dados de Orçamentos</a>
                                    </li>
                                    <li>
                                        <a href="orcamentos">PDFs A partir do OfficeGest</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>Domínios</b>
                                <ul class="sublist">
                                    <li>
                                        <a href="https://dominios.belodigital.com/">Gestor de Domínios</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>Utils</b>
                                <ul class="sublist">
                                     <li>
                                        <a href="/utils/svgPrefix">Prefixar SVGs</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>EPIs</b>
                                <ul class="sublist">
                                     <li>
                                        <a href="lotes">Atualizar Dados de Lotes</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
ul {
   margin: 0;
   padding: 0;
   list-style-type: none;

   li {
      padding-top:0.5rem;
      padding-bottom: 0.5rem;
   }
}

.sublist {
    margin-left: 2rem;
    padding-left: 1rem;
    list-style-type:circle;
}
</style>
