<template>
    <div>
        <h1>Informação dos Lotes Por Processar</h1>
        <ul>
            
                <table>
                    <tr>
                        <th><input type="checkbox"  v-model="todos" /></th>
                        <th>Fatura</th>
                        <th>Cliente</th>
                        <th>Referência</th>
                        <th>Descrição</th>
                        <th>Qtd.</th>
                        <th>Lote</th>
                    </tr>
                    <template v-for="fatura in faturas">
                        <template v-for="(lote, index) in fatura.lotes">
                            <template v-if="index==0">
                                <tr>
                                    <td :rowspan="fatura.lotes.length" class="top center"><input type="checkbox" v-model="selecionados" :id="fatura.fatura_id" :value="fatura.fatura_id"/></td>
                                    <td :rowspan="fatura.lotes.length" class="top"> {{ fatura.tipo_fatura + fatura.fatura_id }} </td>
                                    <td :rowspan="fatura.lotes.length" class="top"> {{ fatura.cliente }} </td>
                                    <td> {{ lote.referencia }}</td>
                                    <td> {{ lote.designacao }}</td>
                                    <td class="right"> {{ Number(lote.quantidade) }}</td>
                                    <td class="center"> {{ lote.lote }}</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td> {{ lote.referencia }}</td>
                                    <td> {{ lote.designacao }}</td>
                                    <td class="right"> {{ Number(lote.quantidade) }}</td>
                                    <td class="center"> {{ lote.lote }}</td>
                                </tr>
                            </template>
                        </template>
                    </template>
                </table>
            <button @click="guardar" class="processButton">Enviar para Folha de Cálculo</button>
        </ul>
    </div>
</template>

<script>
import { OfficeGestAPI } from "../api/OfficeGestAPI";
import { LazyLoadClient } from "../api/LazyLoadClient";
import RestAPI from "../api/RestAPI";
export default {
    data() {
        return {
            officeGestUrl: "https://belosolutions.officegest.com",
            username: "admin",
            token: "14536b645be06b83d2e75797f323fb88e0b24ef3",
            api: null,

            faturas: [],
            expandidas: [],
            selecionados: [],
            todos: true,
            page: 1,
            data: "",
            allowClicks: true,
            onOfficeGestRequest: false,

            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
            apiToken: document
                .querySelector('meta[name="cross-token"]')
                .getAttribute("content")
        };
    },

    mounted() {
        this.api = new RestAPI("", "", this.apiToken);
        this.getFaturasPorProcessar();
    },

    computed: {},

    watch: {
        todos(valor) {
            this.selecionarTodos();
        },

        selecionados(valor) {
            if (valor.length==0)
                this.todos = false;
            else if (valor.length == this.faturas.length) {
                this.todos = true;
            } else {
                this.todos = null;
            }
        }
    },

    methods: {
        async getFaturasPorProcessar() {
            let res = await this.api.get("/api/faturas/belosolutions");
            this.selecionados = [];
            for (const f of res) {
                this.selecionados.push(f.fatura_id);
            }
            this.faturas = res;
        },

        toArray(object) {
            let result = [];
            for (const key in object) {
                if (object.hasOwnProperty(key)) {
                    let obj = object[key];
                    obj.client = new LazyLoadClient(obj.idcustomer, this.api);
                    result.push(object[key]);
                }
            }
            return result;
        },

        toLineArray(object) {
            let result = [];
            let linha = 1;
            while (object[linha]) {
                result.push(object[linha++]);
            }
            return result;
        },

        getLote(descricao) {
            const regex = /\.*(\d(\d|\.)*).*/gm;

            let result = descricao.match(regex);

            if (result) return result[0];

            return "";
        },

        getNomeSimplificado(numero) {
            return (
                "FA" +
                String(numero).substring(0, 4) +
                "/" +
                Number(String(numero).substring(4))
            );
        },

        async getInfoAEnviar() {
            let result = {
                faturas: []
            };
            for (const f of this.faturas) {
                let cliente = (await f.client.getNow()).name;
                console.log(cliente);
                let fatura = {
                    numero: this.getNomeSimplificado(f.number),
                    cliente: cliente,
                    linhas: []
                };

                for (const l of f.lotes) {
                    fatura.linhas.push({
                        id: l.produto.idarticle,
                        descricao: l.produto.description,
                        quantidade: l.produto.quantity,
                        lote: this.getLote(l.lote.description)
                    });
                }
                result.faturas.push(fatura);
            }
            return JSON.stringify(result);
        },

        async guardar() {
            if (this.selecionados.length>0) {
                await this.api.post("/api/faturas/belosolutions/processar", {ids: this.selecionados});
                this.getFaturasPorProcessar();
            }
        },

        toggleExpansao(id) {
            this.$set(this.expandidas, id, !this.expandidas[id]);
        },

        selecionarTodos() {
            if (this.todos!=null) {
                if (!this.todos)
                    this.selecionados = [];
                else {
                    for (const f of this.faturas) {
                        this.selecionados.push(f.fatura_id);
                    }
                }
            }
            
        }
    }
};
</script>

<style lang="scss" scoped>

    .titulo {
        font-size: 1rem;
        padding-top: 0.25rem;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    table,
    th,
    td {
        border: 1px solid black;
    }

    th {
        background: #e1e1e1;
        text-align: center;
    }


ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.top {
    vertical-align: top;
}

.processButton {
   width: 100%;
   background-color: #2d88ff;
   padding: 0.25rem;
   margin: 0.25rem 0;
   color: white;
   font-weight: bolder;
   font-size: 1.25rem;
   border: 0;
   border-radius: 5px;
   
   &:hover {
      background-color: #5ba2ff;
   }
}
</style>
