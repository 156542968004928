<template>
    <div>
        <div class="cabecalho">Orçamentos Disponíveis no OfficeGest</div>
        <div v-if="!allowClicks">A processar. Por favor aguarde...</div>
        <div v-if="onOfficeGestRequest">A aceder ao OfficeGest.</div>
        <div class="listagem">
            <ul>
                <li
                    v-for="(item, index) in paginaOrcamentos"
                    @click="gerarOrcamento(item)"
                >
                    {{ item.number }} -
                    {{
                        item.client.get() != null ? item.client.get().name : ""
                    }}
                </li>
            </ul>
        </div>
        <div class="paginacao">
            <div>Páginas:&nbsp;</div>
            <div
                v-for="i in numPaginas"
                @click="page = i"
                class="pagina"
                :class="page == i ? 'active' : ''"
            >
                {{ i }}
            </div>
        </div>
        <form
            class="hidden"
            method="POST"
            target="_blank"
            ref="formulario"
            action="previewPDF"
        >
            <input type="hidden" name="dados" v-model="data" />
        </form>
    </div>
</template>

<script>
import { OfficeGestAPI } from "../api/OfficeGestAPI";
import { LazyLoadClient } from "../api/LazyLoadClient";

const OBTER_AREA = 0;
const OBTER_SECCAO = 1;
const OBTER_ANOTACOES = 2;
const OBTER_ARTIGOS = 3;


export default {
    data() {
        return {
            officeGestUrl: "https://belodigital.officegest.com",
            username: "prodrigues@belodigital.com",
            token: "5f3457f1e93271aee5d8e69324b525f9b5c66413",
            api: null,

            orcamentos: [],
            page: 1,
            data: "",
            allowClicks: true,
            onOfficeGestRequest: false
        };
    },

    mounted() {
        this.api = new OfficeGestAPI(
            this.officeGestUrl,
            this.username,
            this.token
        );
        this.getOrcamentos();
    },

    methods: {
        toArray(object) {
            let result = [];
            for (const key in object) {
                if (object.hasOwnProperty(key)) {
                    let obj = object[key];
                    obj.client = new LazyLoadClient(obj.idcustomer, this.api);
                    result.push(object[key]);
                }
            }
            return result;
        },

        orderByNumberDesc(array) {
            array.sort((a, b) => {
                return a.number > b.number ? -1 : a.number < b.number ? 1 : 0;
            });
            return array;
        },

        filterByNumber(array) {
            let result = [];
            for (const elem of array) {
                if (String(elem.number).startsWith("2110")) result.push(elem);
            }
            return result;
        },

        async getOrcamentos() {
            this.onOfficeGestRequest = true;
            this.orcamentos = this.orderByNumberDesc(
                this.filterByNumber(
                    this.toArray((await this.api.getOrcamentos()).documents)
                )
            );
            this.onOfficeGestRequest = false;
        },

        async gerarOrcamento(item) {
            if (!this.allowClicks) return;
            this.allowClicks = false;
            this.onOfficeGestRequest = true;
            let json = await this.api.getOrcamento(item.number);
            let artigos = {};
            for (const key in json.lines) {
                if (json.lines.hasOwnProperty(key)) {
                    const linha = json.lines[key];
                    if (!artigos.hasOwnProperty(linha.idarticle)) {
                        let artigo = await this.api.getArtigo(linha.idarticle);
                        artigo = artigo.article;
                        artigos[linha.idarticle] = artigo;
                    }
                    linha.article = artigos[linha.idarticle];
                }
            }
            this.onOfficeGestRequest = false;

            let res = this.converterResultado(json);
            this.data = JSON.stringify(res, null, 2);
            setTimeout(() => {
                this.$refs.formulario.submit();
                this.allowClicks = true;
            }, 250);
        },

        converterResultado(json) {
            let res = {
                document: json.document,
                areas: []
            };

            let areaAtual = null;
            let seccaoAtual = null;
            let artigoAtual = null;

            let estado = OBTER_AREA;

            for (const key in json.lines) {
                if (json.lines.hasOwnProperty(key)) {
                    const linha = json.lines[key];
                    if (linha.idarticle === ".") {
                        switch (estado) {
                            case OBTER_AREA:
                                if (linha.description !== "") {
                                    areaAtual = {
                                        nome: linha.description,
                                        anotacoes: "",
                                        artigos: []
                                    };
                                    estado = OBTER_ANOTACOES;
                                } else {
                                    areaAtual = {
                                        nome: null,
                                        anotacoes: linha.description,
                                        artigos: []
                                    };
                                    estado = OBTER_ANOTACOES;
                                }
                                res.areas.push(areaAtual);

                                break;
                            case OBTER_ANOTACOES:
                                if (linha.description !== "") {
                                    if (areaAtual.anotacoes !== "")
                                        areaAtual.anotacoes += "\n";

                                    areaAtual.anotacoes += linha.description;
                                } else {
                                    areaAtual = null;
                                    estado = OBTER_AREA;
                                }
                                break;
                            case OBTER_ARTIGOS:
                                if (linha.description !== "") {
                                    if (linha.description.indexOf("|") >= 0) {
                                        let partes = linha.description.split(
                                            "|"
                                        );
                                        let descricao = partes[0].trim();
                                        let notaPreco = partes[1].trim();
                                        if (descricao !== "") {
                                            if (artigoAtual.notas !== "")
                                                artigoAtual.notas += "\n";
                                            artigoAtual.notas += descricao;
                                        }
                                        if (notaPreco !== "") {
                                            if (artigoAtual.notasCusto !== "")
                                                artigoAtual.notasCusto += "\n";
                                            artigoAtual.notasCusto += notaPreco;
                                        }
                                    } else {
                                        if (artigoAtual.notas !== "")
                                            artigoAtual.notas += "\n";
                                        artigoAtual.notas += linha.description;
                                    }
                                } else {
                                    artigoAtual = null;
                                    estado = OBTER_AREA;
                                }
                                break;
                        }
                    } else {
                        // Linha com artigo
                        artigoAtual = {
                            linha: linha,
                            notas: "",
                            notasCusto: ""
                        };
                        if (estado === OBTER_AREA) {
                           if (areaAtual===null) {
                              areaAtual = {
                                 nome: null,
                                 anotacoes: "",
                                 artigos: []
                              };
                              res.areas.push(areaAtual);
                           }
                        }
                        areaAtual.artigos.push(artigoAtual);
                        estado = OBTER_ARTIGOS;
                    }
                }
            }
            for (let area of res.areas) {
                if (area.artigos.length === 0 && area.nome !== null) {
                    if (area.anotacoes !== "") {
                        area.anotacoes = area.nome + "\n" + area.anotacoes;
                    } else {
                        area.anotacoes = area.nome;
                    }
                    area.nome = null;
                }
            }

            return res;
        }
    },

    computed: {
        paginaOrcamentos() {
            const pageSize = 30;
            return this.orcamentos.slice(
                (this.page - 1) * pageSize,
                this.page * pageSize - 1
            );
        },

        numPaginas() {
            const pageSize = 30;
            let numPaginas = Math.floor(this.orcamentos.length / pageSize);
            if (numPaginas * pageSize < this.orcamentos.length) numPaginas++;
            return numPaginas;
        }
    }
};
</script>

<style lang="scss" scoped>
.JSONView {
    display: block;
    white-space: pre;
}

.group {
    margin: 20px auto;

    .input,
    input {
        min-width: 80vw;
        padding: 0.5rem;
    }
}

.paginacao {
    display: flex;
    align-items: center;
    .pagina {
        padding: 0.25rem 0.5rem;
        margin: 0.25rem 0.1rem;
        border: 1px solid black;
        cursor: pointer;

        &:hover {
            background: #e1e1e1;
        }

        &.active {
            background: #000;
            color: #fff;
        }
    }
}

.cabecalho {
    font-size: 18pt;
}

.listagem {
    ul {
        list-style-type: none;
    }

    li {
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 0.25rem;
        margin-bottom: 0.25rem;
        &:hover {
            background: #e1e1e1;
        }
    }
}
</style>
