<template>
    <div v-if="!editing && preview" v-html="renderedValue" @click="edit" class="renderArea" />
    <textarea v-else v-model="model" ref="input" @blur="stopEdit" :rows="rows"></textarea>
</template>

<script>
import MarkDownIt from "markdown-it";

export default {
    props: {
        value: {
            required: true,
        },
        preview: {
            type: Boolean,
            default: true,
        },

        rows: {
            type: Number,
            default: 2
        },
    },

    data() {
        return {
            editing: false,
            markdown: new MarkDownIt(),
        };
    },


    computed: {
        renderedValue() {
	    if (!this.model)
              return "";
            this.markdown.set({ breaks: true })
            return this.markdown.render(this.model);
        },

        model: {
            get() {
                return this.value;
            },

            set(newValue) {
                this.$emit("input", newValue);
            }
        }
    },

    watch: {
        renderedValue: {
            immediate: true,
            handler(newValue) {
                if (!newValue)
                    this.editing = true;
            }
        }
    },

    methods: {
        edit() {
            this.editing = true;
            this.$nextTick(()=>{
                this.$refs.input.focus();
            })
        },

        stopEdit() {
            if (this.renderedValue)
                this.editing = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.renderArea::v-deep p{
    margin: 0px;
 }
</style>
