export default class RestAPI {

   constructor(baseUrl, username, token, type="Basic") {
      this.baseUrl = baseUrl;
      if (type==="Basic")
         this.authorization = "Basic " + btoa(username+':'+token);
      else
         this.authorization = "Bearer "+token;
   }

   async get(url) {
      let response = await fetch(
         this.baseUrl + url,
         {
             method: "GET",
             headers: {
                 'Authorization': this.authorization
             }
         }
     );
     let json = await response.json();
     return json;
   }

   async post(url, data) {
      let body = JSON.stringify(data);
      let response = await fetch(
         this.baseUrl + url,
         {
             method: "POST",
             headers: {
                 'Authorization': this.authorization,
                 'Content-Type': 'application/json',
             },
             body: body
         }
     );
     let json = await response.json();
     return json;
   }
}