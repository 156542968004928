<template>
    <div>
        <div class="msgErro">{{mensagemErro}}</div>
        <h1>Orçamentos Registados</h1>
        <div style="text-align: right;"> <a href="/orcamentos/criar">Criar Novo Orçamento</a> </div>
        <table class="tabelaOrcamentos">
            <thead>
                <tr>
                    <th>Orçamento</th>
                    <th>Cliente</th>
		            <th>NIF</th>
                    <th>Data</th>
                    <th>Áreas</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="orcamento in orcamentos" :key="orcamento.id" :class="{bloqueado: isTrue(orcamento.bloqueado)}">
                    <td>{{orcamento.numero}}</td>
                    <td>{{orcamento.nome_cliente}}</td>
                    <td>{{orcamento.nif_cliente}}</td>
                    <td>{{orcamento.data_criacao}}</td>
                    <td>{{orcamento.areas.map(a=>a.nome).join(", ")}}</td>
                    <td style="display: flex; gap: 0.5rem; flex-wrap: wrap;">
                        <a :href="'/orcamentos/editar/' + orcamento.id">Editar</a>
                        <a :href="'/orcamentos/copiar/' + orcamento.id">Criar Cópia</a>
                        <a href="#" @click="previsualizarOrcamento(orcamento.id)">Pré-Visualizar</a>
                        <a v-if="!isTrue(orcamento.bloqueado)" href="#" style="color:red" @click="apagarOrcamento(orcamento)">Apagar</a>
                        <a href="#" @click.stop.prevent="bloquear(orcamento.id)">{{isTrue(orcamento.bloqueado)?"Desbloquear": "Bloquear"}}</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style="text-align: right;"> <a href="/orcamentos/criar">Criar Novo Orçamento</a> </div>
        <form
            class="hidden"
            method="POST"
            target="_blank"
            ref="formulario"
            action="/previewPDF"
        >
            <input type="hidden" name="dados" v-model="dadosProcessados" />
        </form>
    </div>
</template>

<script>
import dayjs from "dayjs";

export default {
    components: {
    },

    data() {
        return {
            orcamentos: [],
            dadosProcessados: null,
            mensagemErro: "",
            timeout: null,
        };
    },

    mounted() {
        this.getOrcamentosExistentes();
    },

    methods: {


        async getOrcamentosExistentes() {
            let result = await axios.get("/api/orcamentos/documentos");
            let orcamentos = result.data;
            
            //orcamentos = orcamentos.substring(orcamentos.indexOf("[{"));
            //orcamentos = JSON.parse(orcamentos);
            
            orcamentos.sort((a,b) => {
                return b.id - a.id;
            });
            this.orcamentos = orcamentos;
        },

        async getDocument(id) {
            let result = await axios.get("/api/orcamentos/documentos/"+id);
            return result.data;
        },

        prepararParaPrevisualizacao(documento) {
            let result = {
                document: {
                    date: dayjs(documento.data_criacao).format("YYYY-MM-DD") +" 00:00",
                    datedue: dayjs(documento.validade).format("YYYY-MM-DD") +" 00:00",
                    nomefact: documento.nome_cliente,
                    documentnumber: documento.numero,
                    moradafact: documento.morada_cliente,
                    morada2fact: documento.morada2_cliente,
                    codpostalent: documento.codigo_postal_cliente,
                    observacoes: documento.observacoes,
                    condicoes_pagamento: documento.condicoes_pagamento,
                    nif: documento.nif_cliente,
                    nome_contacto: documento.nome_contacto,
                    notas: documento.notas,
                },
                areas: [],
                quadrosResumo: [],
            };

            for (let area of documento.areas) {
                let elem = {
                    nome: area.nome,
                    anotacoes: area.anotacoes,
                    quebrarPagina: area.quebrar_pagina,
                    artigos: []
                };
                for (let artigo of area.artigos) {
                    elem.artigos.push({
                        linha: {
                            description: artigo.descricao,
                            totalwithoutvat: artigo.valor_sem_iva,
                        },
                        notas: artigo.notas,
                        notasCusto: artigo.notas_custo
                    });
                }
                result.areas.push(elem);
            }
            result.quadrosResumo = this.obterGrupos(documento);

            return JSON.stringify(result, null, 2);
        },

        async previsualizarOrcamento(id) {
            let documento = await this.getDocument(id);
            this.dadosProcessados=this.prepararParaPrevisualizacao(documento);
            setTimeout(() => {
                this.$refs.formulario.submit();
            }, 250);
        },

        async apagarOrcamento(orcamento) {
            let index = this.orcamentos.indexOf(orcamento);
            if (index<0)
                return;
            if (!confirm("Tem a certeza que quer remover o orçamento \""+orcamento.numero+"\"?\nEsta operação não é reversivel!!!"))
                return;
            let result = await axios.delete("/api/orcamentos/documentos/"+orcamento.id);
            if (result.data === "OK") {
                this.orcamentos.splice(this.orcamentos.indexOf(orcamento), 1);
            }
        },

        async getDocument(id) {
            let result = await axios.get("/api/orcamentos/documentos/"+id);
            return result.data;
        },

        async guardarOrcamento(documento) {
            try {
                let result = await axios.post("/api/orcamentos/documentos", {documento}, { headers: {
                    'Authorization': 'Bearer ' + document.getElementsByName('cross-token')[0].getAttribute('content'),
                }});
            } catch (error) {
                this.showMessage("Erro ao guardar orçamento!", error);
            }
        },

        async bloquear(id) {
            let doc = await this.getDocument(id);
            doc.bloqueado = !this.isTrue(doc.bloqueado);
            await this.guardarOrcamento(doc);
            await this.getOrcamentosExistentes();
        },

        showMessage(message, error) {
            console.log(error);
            this.mensagemErro = message + "\n" + error.message +"\n" + error.response.data.message + "\n" + error.response.data.exception;
            if (this.timeout)
                clearTimeout(this.timeout);
            this.timeout = setTimeout(()=>{this.timeout=null; this.mensagemErro="";}, 10000);
        },

        isTrue(valor) {
            return valor===1 || valor==="1" || valor===true;
        },

        obterGrupos(documento) {
            let result = [];

            for (let area of documento.areas) {
                for (let artigo of area.artigos) {
                    let grupos = artigo.grupo_resumo?.split(";");
                    if (grupos===null || grupos===undefined) continue;
                    for (let grupo of grupos) {
                        if ((grupo ?? "").trim() === "")
                            continue;
                        let grupoExistente = result.find(x => x.grupo === grupo);
                        if (!grupoExistente) {
                            let index = result.push({grupo, areas: [], valorTotal: 0});
                            grupoExistente = result[index-1];
                        }
                        let areaExistente = grupoExistente.areas.find(x => x.area === area.nome);
                        if (!areaExistente) {
                            let index = grupoExistente.areas.push({area: area.nome, artigos: [], valorTotal: 0});
                            areaExistente = grupoExistente.areas[index-1];
                        }
                        areaExistente.artigos.push(artigo);
                        areaExistente.valorTotal += Number(artigo.valor_sem_iva);
                        grupoExistente.valorTotal += Number(artigo.valor_sem_iva);
                    }
                }
            }

            return result;
        },
    }
}
</script>

<style lang="scss" scoped>
.tabelaOrcamentos {
    width: 100%;

    td, th {
        border: 1px solid #c3c3c3;
        padding: 2px 4px;
    }

    thead tr {
        background: #f1f1f1;
        text-align: center;
        text-transform: uppercase;
    }

    .bloqueado {
        background: repeating-linear-gradient(45deg, #d4d4d4, #d4d4d4 10px, #d8d8d8 10px, #d8d8d8 20px);
    }
}

.msgErro {
    color: red;
    white-space: pre;
}
</style>
